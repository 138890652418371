import { IHeaderList } from 'shared/models/headerList.model';

export const SUPPLIER_HEADER_ITEMS = ref<Array<IHeaderList>>([
  {
    name: 'Главная',
    link: '/',
    icon: 'navigation/home',
    disabled: false,
    isDefault: true,
  },
  {
    name: 'Каталог',
    link: '/categories/',
    icon: 'action/gallery-view',
    disabled: true,
    activeOn: ['products', 'search'],
  },
  {
    name: 'Корзина',
    link: '/basket/',
    icon: 'action/cart',
    disabled: true,
  },
  {
    name: 'Профиль',
    link: '/profile/',
    icon: 'navigation/lk',
    disabled: false,
    activeOn: ['supplier'],
  },
]);

export const CLIENT_HEADER_ITEMS = ref<Array<IHeaderList>>([
  {
    name: 'Главная',
    link: '/',
    icon: 'navigation/home',
    disabled: false,
    isDefault: true,
  },
  {
    name: 'Каталог',
    link: '/categories/',
    icon: 'action/gallery-view',
    disabled: false,
    activeOn: ['products', 'search'],
  },
  {
    name: 'Корзина',
    link: '/basket/',
    icon: 'action/cart',
    disabled: false,
  },
  {
    name: 'Профиль',
    link: '/profile/',
    icon: 'navigation/lk',
    disabled: false,
    activeOn: ['client'],
  },
]);

export const UNAUTHORIZED_HEADER_ITEMS = ref<Array<IHeaderList>>([]);

export const OCI_HEADER_ITEMS = ref<Array<IHeaderList>>([
  {
    name: 'Каталог',
    link: '/oci/categories/',
    icon: 'action/gallery-view',
    disabled: false,
    activeOn: ['products', 'search'],
  },
  {
    name: 'Корзина',
    link: '/oci/basket/',
    icon: 'action/cart',
    disabled: false,
  },
]);
