import {
  CLIENT_HEADER_ITEMS,
  SUPPLIER_HEADER_ITEMS,
  UNAUTHORIZED_HEADER_ITEMS,
  OCI_HEADER_ITEMS,
} from 'constants/supplier/supplierHeaderItems.const';
import { ELayout } from 'enums/layouts.enum';
import { IHeaderList } from 'shared/models/headerList.model';
import { useUserStore } from 'store/user.store';
import Notificator from 'shared/services/notificator.service';
import { ENotificationActions } from 'shared/enums/notificationActions.enum';
import { Basket } from 'services/basket-services/basket/basket.service';
import { IUseMobileMenu } from 'models/composables/useMobileMenu';
import { cloneDeep } from 'lodash-es';
import { UserHelper } from 'utils/userHelper.util';
import { Ref } from 'vue';
import { RouterHelper } from 'shared/utils/routerHelper.util';

export function useMobileMenu(layout = ELayout.Default, menuItems?: Ref<Array<IHeaderList>>, ociMode = false): IUseMobileMenu {
  const userStore = useUserStore();
  const notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined;
  const router = useRouter();

  function getMenuItems(): Ref<Array<IHeaderList>> {
    if (ociMode) {
      return OCI_HEADER_ITEMS;
    }

    if (userStore.isUserLoggedIn) {
      return UserHelper.isSupplier
        ? SUPPLIER_HEADER_ITEMS
        : CLIENT_HEADER_ITEMS;
    }

    return UNAUTHORIZED_HEADER_ITEMS;
  }

  menuItems ??= getMenuItems();

  const clickMenuItemLayoutMap = new Map<ELayout, (item: IHeaderList) => void>([
    [ELayout.Default, defaultMenuItemClick],
    [ELayout.Supplier, supplierMenuItemClick],
    [ELayout.Client, clientMenuItemClick],
  ]);
  const profileItemName = 'Профиль';

  function defaultMenuItemClick(item: IHeaderList): void {
    if (item.link === '/basket/') {
      if (!userStore.userInfo) {
        notificator?.setUnauthorizedAction(ENotificationActions.UnauthorizedBasket);
        return;
      }
      Basket.goToBasketPage();
    } else if (item.name === profileItemName) {
      profileItemClick(item);
    } else {
      router.push(item.link);
    }
  }

  function supplierMenuItemClick(item: IHeaderList): void {
    if (item.name === profileItemName) {
      profileItemClick(item);
    } else {
      router.push(item.link);
    }
  }

  function clientMenuItemClick(item: IHeaderList): void {
    if (item.name === profileItemName) {
      profileItemClick(item);
    } else {
      router.push(item.link);
    }
  }

  function onMenuItemClick(item: IHeaderList): void {
    clickMenuItemLayoutMap.get(layout)?.call(this, item);
  }

  function profileItemClick(item: IHeaderList): void {
    router.push(item.link);
  }
  menuItems = ref(cloneDeep(menuItems.value));

  menuItems?.value.map((item) => {
    item.disabled ||= !!item.disabledPages?.some((link) => RouterHelper.includePathWithoutRoot(link));
    return item;
  });

  function isActive(link: IHeaderList, currentRoute = useRoute()?.fullPath) {
    return !!link?.activeOn?.some((route) => currentRoute?.includes(route));
  }

  return {
    menuItems,
    onMenuItemClick,
    isActive,
  };
}
