export class RouterHelper {
  public static includePath(path: string, route = useRoute()): boolean {
    const { fullPath: currentPath } = route;
    return currentPath.includes(path);
  }

  public static includeHash(path: string): boolean {
    const { hash: currentPath } = useRoute();
    return currentPath.includes(path);
  }

  public static includePathWithoutRoot(path: string, route = useRoute()): boolean {
    const { fullPath: currentPath } = route;
    if (path !== '/') {
      return currentPath.includes(path);
    }
    const match = currentPath.match(path);
    return match && currentPath === match[0];
  }

  public static isPathEndWith(part: string): boolean {
    return useRoute().fullPath.endsWith(part);
  }

  public static startWithPath(path: string, route = useRoute()): boolean {
    const { fullPath: currentPath } = route;
    return currentPath.startsWith(path);
  }
}
